import React from "react";
import { Routes, Route } from 'react-router-dom';
import HomePage from "./Components/HomePage";
import AboutUs from "./Components/AboutUs";
import Recordings from "./Components/Recordings";
import Gallery from "./Components/Gallery";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" index element={<HomePage />}></Route>
        <Route path="/about" element={<AboutUs />}></Route>
        <Route path="/recordings" element={<Recordings />}></Route>
        <Route path="/gallery" element={<Gallery />}></Route>
      </Routes>
    </div>
  );
}

export default App;
