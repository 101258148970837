import React from 'react'
import DrawerAppBar from './Navbar'
import Subheading from './SubHeading'
import MasonryImageList from './ImageList'
import Footer from './Footer'

const Gallery = () => {
  return (
    <div>
        <DrawerAppBar />
        <div style={{ padding: "20px" }}></div>
        <Subheading content='Gallery'></Subheading>
        <MasonryImageList />
        <Footer />
    </div>
  )
}

export default Gallery