import React from "react";
import DrawerAppBar from "./Navbar";
import Subheading from "./SubHeading";
import { motion } from "framer-motion";
import ParaContent from "./paraContent";
import "../Stylesheets/About.css";
import Footer from "./Footer";
import About1 from "../Resources/About/About1.png";
import About2 from "../Resources/About/About2.png";

const variants = {
    visible: {opacity: 1, x: 0},
    hidden: {opacity: 0, x: 80}
}

const AboutUs = () => {
  return (
    <div>
      <DrawerAppBar />
      <div style={{ padding: "20px" }}></div>
      <Subheading content="Dr. KS Bhatia" />
      <div className="main-about">
        <div className="about-main">
          <div className="about-left about-page-img profile-image flex-custom center-flex">
            <motion.img
              initial={{ opacity: 0, x: -80 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
              src={About1}
              alt="about-img"
            ></motion.img>
          </div>
          <div className="about-right body-color-blue flex-custom center-flex">
            <div style={{ width: "80%" }} className="about-right-top">
              <h2 className="montserrat about-page-sub-head">Mr. Proptek</h2>
              <ParaContent
                className="quicksand about-text"
                content="Mr. Proptek is an innovative AI-driven platform launched by K.S. Bhatia in October 2024, revolutionizing the real estate industry by simplifying the property buying process. The brand leverages cutting-edge artificial intelligence to enhance decision-making, streamline transactions, and predict market trends, offering a smarter, more efficient, and accessible approach to real estate for buyers and sellers worldwide. Mr. Proptek aims to disrupt traditional real estate practices by providing highly intelligent, data-backed solutions that make property transactions faster, more transparent, and ultimately more profitable. With Mr. Proptek, K.S. Bhatia is committed to shaping the future of real estate through artificial intelligence. By simplifying the property buying process, the platform offers unprecedented advantages for users, enabling them to make informed, data-driven decisions. Mr. Proptek’s mission is to make real estate transactions faster, more transparent, and accessible to people around the world, leveraging AI to predict market trends, streamline deals, and optimize outcomes for both buyers and sellers."
              />
            </div>
          </div>
        </div>
      
        <div className="about-main2">
          
        <div className="about-right body-color-blue flex-custom center-flex">
            <div style={{ width: "80%" }} className="about-right-top">
              <h2 className="montserrat about-page-sub-head">PumpKart</h2>
              <ParaContent
                className="quicksand about-text"
                content="Pumpkart was a groundbreaking company founded by K.S. Bhatia in 2014, with the vision of transforming the water pump industry. By creating a digital-first platform, Pumpkart simplified the often complex and fragmented process of purchasing water pumps and related products. The company’s goal was to make it easier for consumers to access high-quality, reliable pumps without the typical hurdles of navigating physical stores or dealing with fragmented service networks. Through its online platform, Pumpkart provided customers with the convenience of browsing, comparing, and purchasing pumps from the comfort of their homes.
                        The company’s customer-centric approach played a key role in its success. Pumpkart offered detailed product descriptions, specifications, and customer reviews, empowering buyers to make informed decisions. In addition, it streamlined the entire purchase journey by providing delivery and installation services, ensuring a seamless experience from start to finish. This level of convenience and transparency was unprecedented in the water pump industry, setting a new standard for how businesses should engage with their customers."
              />
            </div>
          </div>
          
          <div className="about-left profile-image flex-custom center-flex">
            <motion.img
              variants={variants} 
              initial="hidden"
              transition={{ duration: 0.6, ease: "easeInOut" }}
              whileInView="visible"
              viewport={{once: true, amount: 0.5}}
              src={About2}
              alt="about-img"
            ></motion.img>
          </div> 
        </div>
        </div>
        <Footer />
    </div>
  );
};

export default AboutUs;
