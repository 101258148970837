import React, { useState } from "react";
import "../Stylesheets/Homepage.css";
import Subheading from "./SubHeading";
import SpeakerCard from "./SpeakerCard";
import SpeakerData from "../Resources/content/SpeakerData";
import { motion } from "framer-motion";

const HomeSpeaker = () => {
  const [selectedId, setSelectedId] = useState(null);

  return (
    <div id="speaker-section">
      <Subheading content="Speaker At"></Subheading>
      <div className="recog-cards speaker-cards">
        {SpeakerData.map((item) => (
          <div>
            <motion.div
              layoutId={item.id}
              onClick={() => setSelectedId(item.id)}
            >
              <SpeakerCard image={item.image} />
            </motion.div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeSpeaker;
