const youtubeData = [
    {
        YoutubeID: "832Z_k_ggrU"
    },
    {
        YoutubeID: "ctSGJcRwfEM"
    },
    {
        YoutubeID: "N3fVUYXzS0A"
    },
    {
        YoutubeID: "LYYnQQYr-9A"
    },
    {
        YoutubeID: "Nh5eBqSvDOc"
    },
    {
        YoutubeID: "JsiJPRIBGMo"
    },
    
    {
        YoutubeID: "AAP7QRCPYO4"
    },
    {
        YoutubeID: "vjIAzCvpnzo"
    },
    
    {
        YoutubeID: "pKnuBXwiGiI"
    },
    {
        YoutubeID: "0I54HM3LEUE"
    },
    // {
    //     YoutubeID: "X8G-fPj0Zbs"
    // },
    {
        YoutubeID: "QEIx97YxD_g"
    }, 
    


]

export default youtubeData;