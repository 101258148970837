import React from "react";
import "../Stylesheets/Homepage.css";
import { motion } from "framer-motion";

const variants = {
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: 30 },
};

const Cards = (Props) => {
  return (
    <div>
      <a href={Props.link}>
        <motion.div
          variants={variants}
          initial="hidden"
          whileInView="visible"
          transition={{ duration: 0.4 }}
          viewport={{ once: true, amount: 0.5 }}
          className="recog-card flex-custom center-flex flex-column"
        >
          <div className="recog-card-img">
            <img src={Props.image} className={Props.customClass ? "Recognition-card-img": null} alt="img"></img>
          </div>
          <div className="recog-card-data quicksand body-color-blue">
            <p>{Props.content}</p>
          </div>
        </motion.div>
      </a>
    </div>
  );
};

export default Cards;
