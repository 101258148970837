import React from "react";
import Subheading from "./SubHeading";
import Cards from "./Cards";
import "../Stylesheets/Homepage.css";
import PressData from "../Resources/content/PressCardData";

const HomePress = () => {
  return (
    <div id="press" style={{ padding: "80px 0px" }}>
      <Subheading content="Press" />
      <div className="recog-cards press-cards">
        {PressData.map(item => (
            <Cards 
                image={item.image} 
                content={item.content}
                link={item.link}
            />
        ))}
      </div>
    </div>
  );
};

export default HomePress;
