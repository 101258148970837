import React from "react";
import Subheading from "./SubHeading";
import YouTube from "react-youtube";
import youtubeData from "../Resources/content/RecordingsData";
import "../Stylesheets/Homepage.css"
import MotionButton from "./MotionButton";
import CustomYoutube from "./customYoutube";


const HomeRecordings = () => {

    const opts = {
        height: '100%',
        width: '100%',
      };
  return (
    <div id="recordings">
      <Subheading content="Recordings" />
      <div className="recog-cards youtubecard">
        <CustomYoutube />
        {youtubeData.slice(0, 3).map((item) => (
          <div className={item.YoutubeID ? "youtube-card" : null}>
            {!item.YoutubeID && (
              <img
                src="https://www.dummyimage.com/200x200/faaf37/000000.png&text=Image"
                alt="youtube-thumbnail"
              ></img>
            )}
            {item.YoutubeID && (
              <YouTube videoId={item.YoutubeID} className="youtube" opts={opts}  />
            )}
          </div>
        ))}
      </div>
        <div className="button-container flex-custom center-flex">
        <div className="recording-button">
            <MotionButton location='/recordings' content='Show More' />
        </div>
        </div>
        
      
    </div>
  );
};

export default HomeRecordings;
