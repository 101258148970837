import React from 'react'
import Subheading from './SubHeading'
import DrawerAppBar from './Navbar'
import Footer from './Footer'
import YouTube from "react-youtube";
import youtubeData from "../Resources/content/RecordingsData";
import "../Stylesheets/Homepage.css"
import CustomYoutube from './customYoutube';

const youtubeid = "n9Ba-RnTeiw";

const Recordings = () => {

    const opts = {
        height: '100%',
        width: '100%',
      };

  return (
    <div>
        <DrawerAppBar />
        <div style={{ padding: "20px" }}></div>
        <Subheading content='Recordings'></Subheading>
        <div className="recog-cards youtubecard">
        {/* <div className={youtubeid ? "youtube-card" : null}>
            <CustomYoutube />
        </div> */}
        <CustomYoutube />
        {youtubeData.map((item) => (
          <div className={item.YoutubeID ? "youtube-card" : null}>
            {!item.YoutubeID && (
              <img
                src="https://www.dummyimage.com/200x200/faaf37/000000.png&text=Image"
                alt="youtube-thumbnail"
              ></img>
            )}
            {item.YoutubeID && (
              <YouTube videoId={item.YoutubeID} className="youtube" opts={opts} />
            )}
          </div>
        ))}
      </div>
        <Footer />
    </div>
  )
}

export default Recordings