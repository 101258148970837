import React from "react";
import { motion } from "framer-motion";
import "../Stylesheets/Homepage.css";

const variants = {
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y:30 },
};

const SpeakerCard = (props) => {
  return (
    <motion.div
      variants={variants}
      initial="hidden"
      whileInView="visible"
      transition={{ duration: 0.4 }}
      viewport={{ once: true, amount: 0.5 }}
      className="speaker-card"
    >
      <motion.div className="speaker-image">
        <motion.img
          src={props.image}
          alt="speaker-img"
        ></motion.img>
      </motion.div>
    </motion.div>
  );
};

export default SpeakerCard;
