import image1 from "../Gallery/Gallery (7).jpg";
import image2 from "../Gallery/joshTalks.jpg";
import image5 from "../Gallery/Gallery (3).jpg";
import image6 from "../Gallery/Gallery (4).jpg";
import image7 from "../Gallery/Gallery (5).jpg";
import image11 from "../Gallery/1 (3).jpg";
import image12 from "../Gallery/1 (4).jpg";
import image13 from "../Gallery/1 (20).jpg";
import image15 from "../Gallery/1 (7).jpg";
import image16 from "../Gallery/1 (8).jpg";
import image18 from "../Gallery/1 (10).jpg";
import image20 from "../Gallery/1 (12).jpg";
import image21 from "../Gallery/1 (13).jpg";
import image23 from "../Gallery/1 (15).jpg";
import image27 from "../Gallery/1 (19).jpg";
import image29 from "../Gallery/1 (22).jpg";
import image30 from "../Gallery/1 (47).jpg";
import image33 from "../Gallery/1 (26).jpg";
import image35 from "../Gallery/1 (31).jpg";
import image37 from "../Gallery/1 (30).jpg";
import image38 from "../Gallery/1 (32).jpg";
import image39 from "../Gallery/1 (33).jpg";
import image40 from "../Gallery/1 (34).jpg";
import image41 from "../Gallery/1 (35).jpg";
import image42 from "../Gallery/1 (36).jpg";
import image44 from "../Gallery/1 (38).jpg";
import image45 from "../Gallery/1 (39).jpg";
import image47 from "../Gallery/1 (41).jpg";
import image48 from "../Gallery/1 (45).jpg";
import image50 from "../Gallery/1 (44).jpg";
import image52 from "../Gallery/1 (76).jpg";
import image54 from "../Gallery/1 (51).jpg";
import image56 from "../Gallery/1 (53).jpg";
import image60 from "../Gallery/1 (57).jpg";
import image58 from "../Gallery/1 (55).jpg";
import image59 from "../Gallery/1 (56).jpg";
import image57 from "../Gallery/1 (54).jpg";
import image61 from "../Gallery/1 (58).jpg";
import image62 from "../Gallery/1 (59).jpg";
import image63 from "../Gallery/1 (60).jpg";
import image65 from "../Gallery/1 (62).jpg";
import image66 from "../Gallery/1 (77).jpg";
import image67 from "../Gallery/1 (64).jpg";
import image69 from "../Gallery/1 (66).jpg";
import image70 from "../Gallery/1 (67).jpg";
import image71 from "../Gallery/1 (68).jpg";
import image72 from "../Gallery/1 (69).jpg";
import image73 from "../Gallery/1 (70).jpg";
import image74 from "../Gallery/1 (71).jpg";
import image75 from "../Gallery/1 (72).jpg";
import image76 from "../Gallery/1 (73).jpg";
import image77 from "../Gallery/1 (74).jpg";

const itemData = [
  {
    img: image1,
  },
  {
    img: image2,
  },

  {
    img: image21,
  },
  {
    img: image6,
  },
  {
    img: image7,
  },

  {
    img: image11,
  },
  {
    img: image12,
  },
  {
    img: image5,
  },

  {
    img: image15,
  },
  {
    img: image42,
  },
  {
    img: image18,
  },

  {
    img: image20,
  },
  {
    img: image13,
  },

  {
    img: image44,
  },
  {
    img: image27,
  },
  {
    img: image29,
  },
  {
    img: image30,
  },
  {
    img: image33,
  },
  {
    img: image63,
  },

  {
    img: image39,
  },
  {
    img: image37,
  },
  {
    img: image38,
  },
  {
    img: image35,
  },
  {
    img: image40,
  },
  {
    img: image41,
  },
  {
    img: image16,
  },

  {
    img: image23,
  },
  {
    img: image59,
  },
//   {
//     img: image46,
//   },
  {
    img: image47,
  },
  {
    img: image48,
  },
  {
    img: image50,
  },
  {
    img: image52,
  },
  {
    img: image57,
  },
  {
    img: image56,
  },
  {
    img: image45,
  },
  {
    img: image60,
  },
  
  {
    img: image58,
  },
  {
    img: image69,
  },
  {
    img: image61,
  },
  {
    img: image62,
  },

  {
    img: image65,
  },
  {
    img: image66,
  },
  {
    img: image67,
  },
  {
    img: image54,
  },
  {
    img: image70,
  },
  {
    img: image71,
  },
  {
    img: image72,
  },
  {
    img: image73,
  },
  {
    img: image74,
  },
  {
    img: image75,
  },
  {
    img: image76,
  },
  {
    img: image77,
  },
];

export default itemData;
