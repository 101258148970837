import React from 'react'
import Subheading from './SubHeading'
import '../Stylesheets/Homepage.css'
import Cards from './Cards'
import RecogData from '../Resources/content/RecogCardData'

const RecognitionSection = () => {
  return (
    <div id='recog-section'>
        <Subheading content='Recognition' />
        <div className='recog-cards Recognition-cards'>

        {RecogData.map(item => (
            <Cards 
                image={item.image} 
                content={item.content}
                link={item.link}
                customClass={true}
            />
        ))}
        </div>
    </div>
  )
}

export default RecognitionSection