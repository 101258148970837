import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import itemData from '../Resources/content/GalleryData';
import "../Main.css";
import { useMediaQuery } from '@mui/material';

export default function MasonryImageList() {
    const small = 2;
    const large = 3;

    const isSmallScreen = useMediaQuery('(min-width:700px)');

    const imageProps = {
        cols: isSmallScreen ? large : small
    }


  return (
    <Box sx={{ width: "auto", height: "auto"}} className='gallery-box'>
      <ImageList variant="masonry" {...imageProps} gap={8}>
        {itemData.map((item) => (
          <ImageListItem key={item.img}>
            <img
              srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
              src={`${item.img}?w=248&fit=crop&auto=format`}
              alt={item.title}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
}