import React, { useState } from "react";
import "../Stylesheets/Homepage.css";
import { TextField } from "@mui/material";
import CountryCodeData from "../Resources/content/CountryCodesWithFlags.json";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';

const Footer = () => {
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [phnNumber, setPhnNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [message, setMessage] = useState("");

  const [open, setOpen] = useState(false);
  const [erroropen, setErrorOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch("https://formspree.io/f/mwpkbrqp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fullName,
        email,
        countryCode,
        phnNumber,
        message,
      }),
    });

    if (response.ok) {
      setEmail("");
      setFullName("");
      setPhnNumber("");
      setCountryCode("+91");
      setMessage("");

      setOpen(true);
    } else {
      setErrorOpen(true);
    }
  };

  const customStyle = {
    // Root class for the input field
    "& .MuiOutlinedInput-root": {
        color: "#fff",
        width: "100%",
        // Class for the border around the input field
        "&:hover":{
            borderColor: "#fff"
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#fff",
          borderWidth: "1px",
        },
        "& .MuiOutlinedInput-notchedOutline:before":{
            borderColor: "#fff !important",
        },
        "& .MuiOutlinedInput-notchedOutline:after":{
            borderColor: "#fff !important",
        },
        "&:hover .MuiOutlinedInput-root:before": {
            borderColor: "#fff !important"
        },
        "&:hover .MuiOutlinedInput-root:after": {
            borderColor: "#fff !important"
        }
      },
      // Class for the label of the input field
      "& .MuiInputLabel-outlined": {
        color: "#fff",
      },
      "& .MuiInputBase-root:hover":{
        borderColor: "#fff !important"
      },
      "& .MuiFormHelperText-root":{
        color: "#fff",
      },
      "& .MuiSvgIcon-root":{
        color: "#fff",
      }
  }

  return (
    <div id="footer" className="shapedividers_com-4464">
      <div className="footer-section">
        <h3 className=" flex-custom center-flex montserrat footer-heading">
          Contact
        </h3>
      </div>
      <div className="contact-form flex-custom center-flex">
        <form
          className="footer-form flex-custom flex-column"
          onSubmit={handleSubmit}
        >
          <div className="input-form flex-custom">
            <div className="name-field">
            
              <TextField
                type="name"
                placeholder="Name"
                required
                margin="normal"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                sx={customStyle}
              />
            </div>
            <TextField
              type="email"
              placeholder="Email"
              required
              margin="normal"
              value={email}
              sx={customStyle}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="input-form flex-custom">
            <div className="name-field">
              <TextField
                id="outlined-select-currency"
                select
                label="Country Code"
                defaultValue="+91"
                margin="normal"
                fullWidth
                sx={customStyle}
                helperText="Please select your country code"
                onChange={(e) => setCountryCode(e.target.value)}
                required
              >
                {CountryCodeData.map((option) => (
                  <MenuItem key={option.code} value={option.dial_code}>
                    {option.code} {option.dial_code}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <TextField
              type="number"
              placeholder="Phone No."
              required
              margin="normal"
              value={phnNumber}
              sx={customStyle}
              onChange={(e) => setPhnNumber(e.target.value)}
            />
          </div>
          <div className="input-form message-input">
            <TextField
              id="messageBox"
              multiline
              rows={4}
              margin="normal"
              sx={customStyle}
              fullWidth
              value={message}
              placeholder="Type your message..."
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <button
            type="submit"
            className="button-style body-color-navy"
            style={{ marginTop: "10px" }}
          >
            {" "}
            Submit{" "}
          </button>
        </form>
      </div>
      <div className="social-links flex-custom center-flex">
            <div className="social-media flex-custom center-flex">
                <a href="https://www.instagram.com/k.s_bhatia/profilecard/?igsh=djZ4MGs3OTh5NjV2"><InstagramIcon className="social-icon" /></a>
                <a href="https://www.facebook.com/kamaljeet.bhatia.5?mibextid=ZbWKwL"><FacebookIcon className="social-icon"/></a>
                <a href="mailto:ksb@mrproptek.com"><EmailIcon className="social-icon" /></a>
                <a href="https://www.linkedin.com/in/ksbhatia8"><LinkedInIcon className="social-icon" /></a>
                <a href="https://x.com/KSBHATIA5"><XIcon className="social-icon" /></a>
            </div>
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Form Submitted Successfully!!
        </Alert>
      </Snackbar>
      <Snackbar open={erroropen} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Something Went Wrong!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Footer;
