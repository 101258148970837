import RecogImg1 from "../Homepage/RecogSection/INC.png";
import RecogImg2 from "../Homepage/RecogSection/GCPIT-logo-1.png";
import PressImg3 from "../Homepage/RecogSection/indianex.png";
import PressImg4 from "../Homepage/RecogSection/entracker.png";
import PressImg5 from "../Homepage/RecogSection/ETimes.png";
import PressImg6 from "../Homepage/RecogSection/20181217103846-entrepreneur-india-logo.png";
import PressImg7 from "../Homepage/RecogSection/yourstory.png";
import PressImg8 from "../Homepage/RecogSection/weekend.png";
import PressImg9 from "../Homepage/RecogSection/cropped-LOGO3.png"
import PressImg10 from "../Homepage/RecogSection/ET.png";
import PressImg11 from "../Homepage/RecogSection/ANI.png"
import PressImg12 from "../Homepage/RecogSection/mint.png";
import PressImg13 from "../Homepage/RecogSection/Business.png";
import PressImg14 from "../Homepage/RecogSection/FE.png";
import PressImg15 from "../Homepage/RecogSection/hindu.png"
import PressImg16 from "../Homepage/RecogSection/ndtv.png";
import PressImg17 from "../Homepage/RecogSection/dainik bhaskar.png";
import PressImg18 from "../Homepage/RecogSection/VC circle.png";
import PressImg19 from "../Homepage/RecogSection/silicon.png";


const PressData = [
    {
        image: RecogImg1,
        content: "PumpKart Secures Funding To Make Buying Water Pumps An Online Affair",
        link: "https://inc42.com/buzz/exclusive-pumpkart-funding/"
    },
    {
        image:  PressImg3,
        content: "Pumpkart founder K S Bhatia: Never underestimate the young, they are the future",
        link: "https://indianexpress.com/article/india/india-news-india/pumpkart-founder-k-s-bhatia-never-underestimate-the-young-they-are-the-future/"
    },
    {
        image: PressImg10,
        content: "CII Chairman for Startups invests in Pumpkart.",
        link: "https://economictimes.indiatimes.com/small-biz/startups/newsbuzz/cii-chairman-for-startups-invests-in-pumpkart/articleshow/71722497.cms"
    },
    {
        image: PressImg5,
        content: "Pumpkart, Figgital to open over 1,200 modern retail stores in Uttar Pradesh.",
        link: "https://retail.economictimes.indiatimes.com/news/e-commerce/e-tailing/pumpkart-figgital-to-open-over-1200-modern-retail-stores-in-uttar-pradesh/88247962"
    },
    {
        image: PressImg11,
        content: "We will establish R-commerce across India, says Pumpkart founder",
        link: "https://www.aninews.in/news/business/business/we-will-establish-r-commerce-across-india-says-pumpkart-founder20210302135305/"
    },
    {
        image: PressImg12,
        content: "Pumpkart, Figgital to open 1,200 retail stores, 75 experience centres in UP",
        link: "https://www.livemint.com/companies/start-ups/pumpkart-figgital-to-open-1-200-retail-stores-75-experience-centres-in-up-11639313784333.html"
    },
    {
        image: PressImg13,
        content: "Pumpkart earning profits, says company",
        link: "https://www.business-standard.com/article/pti-stories/pumpkart-earning-profits-says-company-119110300578_1.html"
    },
    {
        image: PressImg18,
        content: "Online Farm Equipment Firm PumpKart Raises $2 Mn.",
        link: "https://www.vccircle.com/online-farm-equipment-firm-pumpkart-raises-2-mn"
    },
    {
        image: PressImg14,
        content: "Pumpkart to enter into home and kitchen appliances segment",
        link: "https://www.financialexpress.com/world-news/pumpkart-to-enter-into-home-and-kitchen-appliances-segment/1321914/"
    },
    {
        image: PressImg4,
        content: "Pumpkart gets $2 Mn under Samruddhi scheme",
        link: "https://entrackr.com/2022/01/pumpkart-gets-2-mn-under-samruddhi-scheme/"
    },
    {
        image: PressImg15,
        content: "Pumped up by its potential",
        link: "https://www.thehindubusinessline.com/news/variety/pumped-up-by-its-potential/article7236138.ece"
    },
    {
        image: PressImg16,
        content: "Sundar Pichai praised and changed the company's fortune overnight",
        link: "https://ndtv.in/videos/google-ceo-sundar-pichai-praised-pumpkart-dot-com-384848"
    },
    {
        image: PressImg6,
        content: "Mr. K.S. BHATIA: A Visionary and a seasoned entrepreneur",
        link: "https://www.entrepreneurindia.com/live/ksbhatia.php"
    },
    {
        image: PressImg7,
        content: "Chandigarh-based B2B marketplace Pumpkart is carving a niche by selling water pumps online",
        link: "https://yourstory.com/2016/03/pumpkart"
    },
    {
        image: PressImg9,
        content: "Interview with Dr K.S. Bhatia | Founder and CEO at Pumpkart.com",
        link: "https://www.brilliantread.com/interview-with-dr-k-s-bhatia-founder-and-ceo-at-pumpkart-com/"
    },
    {
        image: PressImg7,
        content: "[Startup Bharat] After 2 failed startups and recognition from Google CEO, this founder built a marketplace for farming needs",
        link: "https://yourstory.com/2022/02/startup-bharat-pumpkart-retail-chain-farming-equipment-agritech"
    },
    {
        image: PressImg17,
        content: "हिमाचल प्रदेश में 10000 नौकरियों के अवसर तैयार करेगा पम्पकार्ट",
        link: "https://www.bhaskar.com/chandigarh/news/news/pumpkart-to-create-10000-jobs-in-himachal-pradesh-071505-5249068.html"
    },
    {
        image: PressImg8,
        content: "From selling shawls and sweaters to building a Rs 17 crore turnover business, he has seen several ups and downs in life",
        link: "https://www.theweekendleader.com/Success/2997/all-pumped-up.html"
    },
    {
        image: RecogImg2,
        content: "Mr. K.S. BHATIA: A Visionary and a seasoned entrepreneur",
        link: "https://gcpit.org/speaker/dr-k-s-bhatia/"
    },
    {
        image: PressImg19,
        content: "Pumpkart seeks to secure its position as a leading Branded e-commerce company in the Indian market",
        link: "https://thesiliconreview.com/magazine/profile/from-just-an-idea-to-a-successful-b2b-company-pumpkart-seeks-to-secure-its-position-as-a-leading-branded-e-commerce-company-in-the-indian-market",
    }

]

export default PressData;