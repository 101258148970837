import SpeakerImg1 from "../Homepage/Speaker/general_assembly.jpeg";
import SpeakerImg2 from "../Homepage/Speaker/lpu-logo-01.jpg";
import SpeakerImg3 from "../Homepage/Speaker/amity.jpg";
import SpeakerImg4 from "../Homepage/Speaker/josh_talks.png";
import SpeakerImg5 from "../Homepage/Speaker/ISBlogo.png";
import SpeakerImg6 from "../Homepage/Speaker/PEC.png";
import SpeakerImg7 from "../Homepage/Speaker/thapar.png";
import SpeakerImg8 from "../Homepage/Speaker/IITRopar.png";
import SpeakerImg10 from "../Homepage/Speaker/iim amritsar.webp"
import SpeakerImg11 from "../Homepage/Speaker/CSC.png";
import SpeakerImg12 from "../Homepage/Speaker/RMAI.png";
import SpeakerImg13 from "../Homepage/Speaker/CII.png";
import SpeakerImg14 from "../Homepage/Speaker/imt.png";
import SpeakerImg15 from "../Homepage/Speaker/phd.png";
import SpeakerImg16 from "../Homepage/Speaker/IIM.png";

const SpeakerData = [
    {
        id: 1,
        image: SpeakerImg1
    },
    {
        id: 4,
        image: SpeakerImg4
    },
    {
        id: 15,
        image: SpeakerImg16
    },
    {
        id: 5,
        image: SpeakerImg5
    },
    {
        id: 13,
        image: SpeakerImg14
    },
    {
        id: 12,
        image: SpeakerImg13
    },
    {
        id: 3,
        image: SpeakerImg3
    },
    {
        id: 10,
        image: SpeakerImg11
    },
    {
        id: 11,
        image: SpeakerImg12
    },
    {
        id: 6,
        image: SpeakerImg6
    },
    {
        id: 7,
        image: SpeakerImg7
    },
    {
        id: 8,
        image: SpeakerImg8
    },
    {
        id: 9,
        image: SpeakerImg10
    },
    {
        id: 14,
        image: SpeakerImg15
    },
    
    {
        id: 2,
        image: SpeakerImg2
    },

]

export default SpeakerData;