import React from 'react';
import { motion } from 'framer-motion';
import "../Main.css";

const variants = {
    visible: {opacity: 1, y: 0},
    hidden: {opacity: 0, y: 30}
}

function MotionButton(props){

    function handleClick(){
        window.location.href = `${props.location}`;
    }

    return(
        <div className='content quicksand'>
            <motion.button 
                variants={variants} 
                initial="hidden" 
                whileInView="visible" 
                transition={{duration: 0.5}} 
                viewport={{once: true, amount: 0.5}}
                className='button-style body-color-navy' 
                onClick={handleClick}
            >{props.content}</motion.button>
        </div>
    );
}

export default MotionButton;