import React from 'react'
import "../Stylesheets/Homepage.css";
import {motion} from "framer-motion";
import Subheading from './SubHeading';
import ParaContent from './paraContent';
import MotionButton from './MotionButton';
import AboutImg from '../Resources/Homepage/HomeAbout.jpg';

const HomeAbout = () => {

  return (
    <div id='about-section'>
        <div className='about-main about-mobile'>
            <div className='about-left profile-image flex-custom center-flex'>
                <motion.img initial={{opacity:0, x:-80}} animate={{opacity:1, x:0}} transition={{duration: 0.6, ease: "easeInOut"}} src={AboutImg} alt='about-img'></motion.img>
            </div>
            <div className='about-right body-color-blue flex-custom center-flex'>
                <div className='about-right-top'>
                    <Subheading content="About Me" />
                    <ParaContent className="quicksand about-text" content="I am the founder of Mr. Proptek, an AI-powered platform transforming the real estate buying process. With over 25 years in business and a track record for disruptive innovation, I previously founded Pumpkart, which redefined the water pumps industry. Recognized as a global thought leader, I’ve spoken at the United Nations SDG Summit and earned accolades like the Leadership Award from RMAI and Amity University, and recognition from TIMES as a Top 30 leader. I hold a B.Tech in Chemical Engineering from Bangalore University, an MBA from IMT Ghaziabad, and an honorary doctorate from Sorbonne University, France. With a commitment to shaping the future of AI in proptech, I continue to push the boundaries of what's possible while empowering others to embrace innovation and drive progress." />
                    <MotionButton location="/about" content='Know More' />
                </div>            
            </div>
        </div>
    </div>
  )
}

export default HomeAbout