import React, { useRef, useCallback, useState } from "react";
import ReactPlayer from "react-player";
import "../Stylesheets/Homepage.css";

const youtubeid = "n9Ba-RnTeiw";

const CustomYoutube = () => {
  const playerRef = useRef();
  const [playing, setPlaying] = useState(false);

  const onReady = useCallback(() => {
    const timeToStart = 16 * 60 + 33;
    playerRef.current.seekTo(timeToStart, "seconds");
    setPlaying(true);
  }, []);
  return (
    <div className={youtubeid ? "youtube-card" : null}>
      <ReactPlayer
        url="https://www.youtube.com/watch?v=n9Ba-RnTeiw"
        ref={playerRef}
        playing={playing}
        onReady={onReady}
        controls={true}
        width="100%"
        height="100%"
      ></ReactPlayer>
    </div>
  );
};

export default CustomYoutube;
