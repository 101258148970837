import React from "react";
import { motion } from "framer-motion";
import "../Stylesheets/Homepage.css";
import ParaContent from "./paraContent";

const WorkCard = (props) => {
  return (
    <div>
      <div className="work-card flex-custom">
        <div className="work-card-image">
          <motion.img
            src={props.image}
            initial={{ opacity: 0, x: -80 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
          ></motion.img>
        </div>
        <div className="work-card-data body-color-blue">
          <h3 className="montserrat work-sub-heading">{props.title}</h3>
          <ParaContent content={props.content} />
        </div>
      </div>
      {props.last ? <hr className="none-hr"></hr> : <hr className="horizontal"></hr>} 
    </div>
  );
};

export default WorkCard;
