import React from 'react'
import Subheading from './SubHeading'
import "../Stylesheets/Homepage.css";
import WorkCard from './WorkCard';
import WorkImg1 from '../Resources/Homepage/WorkSection/Mrproptek.png';
import WorkImg2 from '../Resources/Homepage/WorkSection/pumpkart_logo.jpeg';
import WorkImg3 from '../Resources/Homepage/WorkSection/figgital.jpeg';
import WorkImg4 from '../Resources/Homepage/WorkSection/air.png';

const WorkSection = () => {
  return (
    <div className='work-section'>
        <Subheading content='Works' />
        <div className='work-main'>
            <WorkCard 
                image= {WorkImg1}
                title='Mr. Proptek'
                content='Mr. Proptek is an AI-driven platform, launched by K.S. Bhatia in October 2024, transforming the real estate industry by simplifying the property buying process. The brand leverages artificial intelligence to enhance decision-making, streamline transactions, and predict market trends. Focused on disrupting traditional real estate practices, Mr. Proptek offers smarter, more efficient, and accessible solutions for buyers and sellers worldwide. Mr. Proptek will soon be a Global Brand.'
            />
            
            <WorkCard 
                image={WorkImg2}
                title='PumpKart'
                content='Pumpkart, founded by K.S Bhatia in May 2014, is an Indian e-commerce platform that revolutionized the water pump market by providing a wide range of pumps and hardware products online. Recognized for its impact, even highlighted by Google’s Sundar Pichai in front of Prime Minister of India, Pumpkart streamlined a fragmented industry and empowered small manufacturers with a digital marketplace to reach broader audiences.'
            />
            
            <WorkCard 
                image={WorkImg3}
                title='Figgital'
                content='Figgital, founded in October 2020 by K.S Bhatia with an initial investment of ₹25,000, merged physical and digital retail experiences through an omnichannel model. The brand gained significant traction and also received a grant of ₹3.5 crore. In 2023, Figgital was successfully sold for ₹2.75 crore, showcasing its rapid growth and innovation.'
            />

            <WorkCard 
                image={WorkImg4}
                title='Air Fluid Engineers & Equipments'
                content='Airfluid Engineers & Equipments, founded by K.S Bhatia in May 1998 with an initial investment of ₹45,000, specialized in B2B where company taking contracts for installing Pumping Systems in Northern India. By 2012, the company grew to ₹25 crore in revenue. Operational until March 2019, AirFluid was one of the leading water pumps contractor in Northern India accross industry, commercial Buildings, institutions, OEMs. '
                last="true"
            />
        </div>
    </div>
  )
}

export default WorkSection